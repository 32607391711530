<template>
    <Modal :title="title" v-model="show" draggable class-name="vertical-center-modal"
           @on-cancel="cancel">
        <Form ref="formAddRef" :model="formItem" :label-width="80" :rules="ruleValidate">
            <FormItem label="机构名称" prop="name">
                <Input v-model="formItem.name" placeholder="请输入机构名称"></Input>
            </FormItem>
            <FormItem label="机构类型" prop="type">
                <Select v-model="formItem.type">
                    <Option v-for="item in orgType" :value="item.code" :key="item.code">{{item.label}}</Option>
                </Select>
            </FormItem>
            <FormItem label="描述"  prop="remark">
                <Input type="textarea" v-model="formItem.remark" placeholder="请输入描述"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button  @click="cancel">取消</Button>
            <Button type="primary" :loading="loading" @click="save">保存</Button>
        </div>
    </Modal>
</template>

<script>
    export default {
        name: "UpdateOrg",
        data(){
            return {
                title:"修改机构",
                formItem:{
                    name: '',
                    type: '',
                    remark: '',
                    lowerLevel: false,
                    parentUuid: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '机构名称不能为空', trigger: 'blur' },
                        { type: 'string', max: 64, message: '机构名称不能超过64个字符', trigger: 'blur' }
                    ],
                    type: [
                        { required: true, message: '机构名称类型不能为空', trigger: 'change' }
                    ],
                    remark: [
                        { type: 'string', max: 100, message: '描述信息不能超过100个字符', trigger: 'blur' }
                    ]
                },
                orgType: [],
                show: false,
                loading: false
            }
        },
        methods:{
            cancel() {
                this.show = false
                this.formItem =  this.$options.data().formItem
                this.$refs.formAddRef.resetFields();
            },
            save(){
                this.$refs.formAddRef.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.post("/system/org/update", this.formItem).then(() => {
                            this.$Message.info({
                                content: '保存成功'
                            });
                            this.$emit("updateSuccess", this.formItem)
                            this.loading = false;
                            this.cancel()
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                })
            },
            showModel(data, orgType){
                this.formItem =  this.$options.data().formItem
                Object.assign(this.formItem, data)
                this.orgType = orgType
                this.$refs.formAddRef.resetFields();
                this.show = true
            }
        },
    }
</script>

<style scoped>
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ivu-modal{
        top: 0;
    }
</style>