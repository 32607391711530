<template>
    <div style="height: 100%">
        <Row type="flex" justify="end">
            <Col span="3">
                <div style="text-align: right">
                    全部展开：<el-switch
                        v-model="expandAll"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="expandChange">
                </el-switch>
                </div>
            </Col>
            <Col span="2">
                <div style="text-align: right">
                    横排：<el-switch
                        v-model="horizontal"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    >
                    </el-switch>
                </div>
            </Col>
        </Row>
        <Divider />
        <div style="display: flex;justify-content: center;">
            <Vue2OrgTree v-if="tree" :data="tree"
                 :horizontal="!horizontal"
                 :collapsable="collapsable"
                 :render-content="renderContent"
                 @on-expand="onExpand"
                 @on-node-click="onNodeClick"
                 ></Vue2OrgTree>
        </div>
        <AddOrg ref="addOrgRef" @addSuccess="addSuccess"></AddOrg>
        <UpdateOrg ref="updateOrgRef" @updateSuccess="updateSuccess"></UpdateOrg>
    </div>
</template>

<script>
    import Vue2OrgTree from 'vue2-org-tree'
    import "vue2-org-tree/dist/style.css"
    import { cloneDeep } from 'lodash';
    import AddOrg from './AddOrg.vue'
    import UpdateOrg from './UpdateOrg.vue'
    export default {
        name: "Org",
        components: {
            Vue2OrgTree,
            AddOrg,
            UpdateOrg
        },
        created() {
            this.getOrgTypeList()
            this.getList()
        },
        data() {
            return {
                horizontal: true,
                collapsable: false,
                expandAll: true, //是否全部展开
                data: [],
                tree: null,
                orgType: []
            }
        },
        methods: {
            getOrgTypeList() {
                this.post("/system/org/getOrgTypeList", {}).then(value => {
                    this.orgType = value.data.data
                }).catch(reason => {
                    console.log(reason);
                    this.$Message.warning("机构类型失败！")
                })
            },
            getList() {
                // 后台回去的数据 赋值给data即可
                this.post("/system/org/list", {}).then(value => {
                    this.data = value.data.data
                    console.log(this.data)
                    this.initTree();
                }).catch(reason => {
                    console.log(reason);
                    this.$Message.warning("机构加载失败！")
                })
            },
            initTree(){
                let orgParentUuidOrgMap = {}
                this.data.forEach(org => {
                    if(!org.parentUuid){
                        orgParentUuidOrgMap[''] = [org]
                    } else {
                        if(!orgParentUuidOrgMap[org.parentUuid]){
                            orgParentUuidOrgMap[org.parentUuid] = []
                        }
                        orgParentUuidOrgMap[org.parentUuid].push(org)
                    }
                })
                let loopOrg = (org) => {
                    let arr = orgParentUuidOrgMap[org.uuid];
                    if(arr){
                        org['children'] = arr
                        arr.forEach(childOrg => {
                            childOrg['id'] = childOrg.uuid
                            childOrg['label'] = childOrg.name

                            loopOrg(childOrg)
                        })
                    }
                }
                let rootList = this.data.filter(org => {
                    return !org.parentUuid;
                })
                rootList.forEach(rootOrg => {
                    rootOrg['id'] = rootOrg.uuid
                    rootOrg['label'] = rootOrg.name
                    loopOrg(rootOrg, this.data)
                })

                this.tree = cloneDeep(rootList[0]);
                this.$nextTick(() => {
                    this.expandChange()
                })
            },
            renderContent(h, data) {
                if(data.parentUuid){
                    return (
                        <Dropdown trigger='contextMenu' placement='right-start' onon-click={(command) => this.orgMenuClick(data, command)}>
                            <a class='org-label' href='javascript:void(0)' style='color: #000;'>
                            {data.label}
                            </a>
                            <DropdownMenu slot='list'>
                                <DropdownItem name="add">新增</DropdownItem>
                                <DropdownItem name='modify'>修改</DropdownItem>
                                <DropdownItem name='del'>删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )
                }
                return (
                    <Dropdown trigger='contextMenu' placement='right-start' onon-click={(command) => this.orgMenuClick(data, command)}>
                        <a class='org-label' href='javascript:void(0)' style='color: #000;'>
                            {data.label}
                        </a>
                        <DropdownMenu slot='list'>
                            <DropdownItem name="add">新增</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                )
            },
            onExpand(e, data) {
                const _this = this
                if ('expand' in data) {
                    data.expand = !data.expand
                    if (!data.expand && data.children) {
                        _this.collapse(data.children)
                    }
                } else {
                    _this.$set(data, 'expand', true)
                }
            },
            // 自定义您的点击事件
            onNodeClick(e, data) {
                console.log("点击", e, data);
            },
            collapse(list) {
                var _this = this
                list.forEach(function(child) {
                    if (child.expand) {
                        child.expand = false
                    }
                    child.children && _this.collapse(child.children)
                })
            },
            expandChange() {
                this.collapsable = true
                this.toggleExpand(this.tree, this.expandAll)
            },
            toggleExpand(data, val) {
                var _this = this
                if (Array.isArray(data)) {
                    data.forEach(function(item) {
                        _this.$set(item, 'expand', val)
                        if (item.children) {
                            _this.toggleExpand(item.children, val)
                        }
                    })
                } else {
                    this.$set(data, 'expand', val)
                    if (data.children) {
                        _this.toggleExpand(data.children, val)
                    }
                }
            },
            orgMenuClick(data, command){
                if(command === 'add'){
                    this.$refs.addOrgRef.showModel(data.uuid, this.orgType)
                }else if(command === 'modify'){
                    this.$refs.updateOrgRef.showModel(data, this.orgType)
                }else if(command === 'del'){
                    console.log(data)
                    this.$Modal.confirm({
                        title: '警告',
                        content: '确定要删除“' + data.name + '“部门？？？',
                        onOk: () => {
                            this.post("/system/org/del", {
                                uuid: data.uuid
                            }).then(() => {
                                this.$Message.info({
                                    content: '删除成功'
                                });
                                this.data.forEach(value => {
                                    if(value.uuid == data.parentUuid){
                                        let children =value.children.filter(value => value.uuid !== data.uuid)
                                        value.children = children;
                                    }
                                })
                                this.data = this.data.filter(value => value.uuid !== data.uuid)
                                this.initTree();
                            })
                        }
                    })
                }
            },
            addSuccess(org){
                this.data.forEach(value => {
                    if(value.uuid == org.parentUuid){
                        if(!value.children){
                            value.children = []
                        }
                        org['id'] = org.uuid
                        org['label'] = org.name
                        value.children.push(org)
                    }
                })
                this.data.push(org)
                this.initTree();
            },
            updateSuccess(org){
                this.data.forEach(value => {
                    if(value.uuid == org.uuid){
                        org['id'] = org.uuid
                        org['label'] = org.name
                        Object.assign(value, org)
                    }
                })
                this.initTree();
            }
        }
    }
</script>

<style scoped>
    /deep/ .org-tree-node-label-inner{
        padding: 0px 0px;
    }
    /deep/ .org-label{
        padding: 10px 15px;
        display: inline-block;
    }
</style>